import { updateUser } from '@/api/user';
import { Button, StyledForm, Text } from '@/components/common';
import {
  CountryPickerField,
  FormMarginDense,
  FormWrapper,
  SearchPostcodeField,
  StatePickerField,
  TextField,
} from '@/components/form';
import { useAppDispatch, useAppSelector } from '@/redux/hooks';
import { Formik, FormikHelpers } from 'formik';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { KYCLayout } from '../../container/KYCLayout';
import { useOnfido } from '../../hook/useOnfido';
import DVLA from './DVLA';

const schema = yup.object().shape({
  postal_code: yup.string().required('Postcode is required'),
  line1: yup.string().required('Address line 1 is required'),
  city: yup.string().required('City is required'),
});

const ConfirmAddressPage: any = () => {
  const [manualInput, setManualInput] = React.useState(false);
  const [showDVLA, setShowDVLA] = React.useState(false);
  const [address, setAddress] = React.useState(null);
  const userId = useAppSelector((state: any) => state.auth.userId);
  const dispatch = useAppDispatch();
  const [, , , nextStep] = useOnfido(userId);
  const formikRef = useRef();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch({ type: 'HIDE_LOADING' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setOpenManual = (value: boolean) => {
    setManualInput(value);
  };

  const selectPostcode = (value: any) => {
    setManualInput(true);
    // @ts-ignore
    const { country, ...formValue } = value;
    Object.keys(formValue).forEach((item) => {
      // @ts-ignore
      formikRef.current.setFieldValue(item, value[item], true);
      // @ts-ignore
      setTimeout(() => formikRef.current.setFieldTouched(item, true));
      // @ts-ignore
    });
  };

  const clearValueWhenChangeCountry = () => {
    // @ts-ignore
    formikRef.current.resetForm();
  };

  useEffect(() => {
    if (!manualInput) {
      // @ts-ignore
      formikRef.current.setFieldValue('line1', undefined);
      // @ts-ignore
      formikRef.current.setFieldValue('line2', undefined);
      // @ts-ignore
      formikRef.current.setFieldValue('city', undefined);
    }
  }, [manualInput]);

  const onContinue = async (values: any, form: FormikHelpers<any>) => {
    dispatch({ type: 'SHOW_LOADING' });
    setAddress(values);
    setShowDVLA(true);
  };
  const onSubmit = async () => {
    if (!address) {
      setShowDVLA(false);
      return;
    }
    dispatch({ type: 'SHOW_LOADING' });
    await updateUser(userId, { address: address, dvla_consent: true })
      .then(() => {
        window.location.href = 'https://www.dev.theout.com/profile/licence';
        nextStep(3);
      })
      .catch((error) => {
        if (['DVLA', 'BVRLA', 'Onfido', 'UserTooYoung'].includes(error)) {
          dispatch({ type: 'NEXT_STEP_AUTO' });
          dispatch({
            type: 'UPDATE_STATUS',
            payload: { verify_identity_passed: true },
          });
          return;
        }
        dispatch({ type: 'HIDE_LOADING' });
      });
  };
  return (
    <>
      {showDVLA ? (
        <DVLA onSubmit={onSubmit}></DVLA>
      ) : (
        <FormWrapper>
          <Text color="darkPrimary" variant="title">
            {t('CONFIRM_YOUR_ADDRESS')}
          </Text>
          <Text variant="content" color="darkSecondary">
            {t('CONFIRM_YOUR_ADDRESS_SUBTITLE')}
          </Text>
          {/* @ts-ignore */}
          <Formik
            initialValues={{ country: 'GBR', postal_code: '' }}
            validationSchema={schema}
            onSubmit={onContinue}
            //@ts-ignore
            innerRef={formikRef}
          >
            {(props) => (
              <StyledForm>
                <div>
                  <FormMarginDense>
                    <CountryPickerField
                      name="country"
                      config={{
                        fullWidth: true,
                        label: t('ISSUING_COUNTRY'),
                        placeholder: 'Choose Country',
                      }}
                      initialValues={props.initialValues}
                      clearValue={clearValueWhenChangeCountry}
                    />
                  </FormMarginDense>

                  <FormMarginDense>
                    <SearchPostcodeField
                      name="postal_code"
                      config={{
                        fullWidth: true,
                        label: props?.values?.country === 'USA' ? t('ZIPCODE') : t('POSTCODE'),
                        placeholder: 'e.g. W1CX',
                      }}
                      setOpenManual={(val: boolean) => setOpenManual(val)}
                      selectPostcode={(value: any) => selectPostcode(value)}
                      manualInput={manualInput}
                      countryValue={props?.values?.country}
                    />
                  </FormMarginDense>
                  {manualInput ? (
                    <>
                      <FormMarginDense>
                        <TextField
                          type="text"
                          name="line1"
                          config={{
                            label: t('ADDRESS_LINE_1'),
                            InputProps: {
                              'data-testid': 'test_address_line1',
                            },
                          }}
                        />
                      </FormMarginDense>
                      <FormMarginDense>
                        <TextField
                          type="text"
                          name="line2"
                          config={{
                            label: t('ADDRESS_LINE_2'),
                          }}
                        />
                      </FormMarginDense>
                      <FormMarginDense>
                        <TextField
                          type="text"
                          name="city"
                          config={{
                            label: t('CITY'),
                            InputProps: {
                              'data-testid': 'test_city',
                            },
                          }}
                        />
                      </FormMarginDense>
                    </>
                  ) : null}
                  {props?.values?.country === 'USA' ? (
                    <>
                      <FormMarginDense>
                        <StatePickerField
                          name="state"
                          config={{
                            fullWidth: true,
                            label: t('STATE'),
                          }}
                          clearValue={clearValueWhenChangeCountry}
                        ></StatePickerField>
                      </FormMarginDense>
                      <FormMarginDense value="100"></FormMarginDense>
                    </>
                  ) : null}
                </div>
                <div>
                  <FormMarginDense>
                    <Button
                      disabled={!props.values?.postal_code || !props.isValid}
                      fullWidth
                      variant="contained"
                      color="white"
                      type="submit"
                    >
                      {t('CONTINUE')}
                    </Button>
                  </FormMarginDense>
                </div>
              </StyledForm>
            )}
          </Formik>
        </FormWrapper>
      )}
      <div id="onfido-mount"></div>
    </>
  );
};

ConfirmAddressPage.getLayout = KYCLayout;
export default ConfirmAddressPage;
