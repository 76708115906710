import { updateUser } from '@/api/user';
import { Text } from '@/components/common';
import { CountryPickerField, DatePickerField, FormMarginDense, FormWrapper, TextField } from '@/components/form';
import { covertDateBeforeSubmit } from '@/components/form/DatePickerField/dateFunction';
import { useOnfido } from '@/hooks/useOnfido';
import { useAppDispatch, useAppSelector } from '@/redux/hooks';
import {
  BirthdateSchema,
  DateExpireSchema,
  FirstNameSchema,
  IssuingDateSchema,
  LastNameSchema,
} from '@/utils/validation';
import { Formik, FormikHelpers } from 'formik';
import { get } from 'lodash';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { KYCLayout } from '../../container/KYCLayout';
import { ButtonWrapper, ExpireDateWrapper, ExpireLicenseWrapper, StyledForm } from './styles';

const schema = yup.object().shape({
  first_name: FirstNameSchema,
  last_name: LastNameSchema,
  birthdate: BirthdateSchema,
  issuing_date: IssuingDateSchema,
  date_of_expiry: DateExpireSchema,
  number: yup.string().required('Licence number is required'),
});

const ConfirmLicencePage: any = () => {
  const licenceData = useAppSelector((state) => get(state, 'user.userInfo.licence'));
  const userId = useAppSelector((state: any) => state.auth.userId);
  const [, , , nextStep] = useOnfido(userId);
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch({ type: 'HIDE_LOADING' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const onSubmit = async (values: any, form: FormikHelpers<any>) => {
    try {
      console.log(123);
      dispatch({ type: 'SHOW_LOADING' });
      const payloadLicence = {
        birthdate: covertDateBeforeSubmit(values?.birthdate),
        first_name: values?.first_name?.toUpperCase(),
        issuing_country: values?.issuing_country,
        issuing_date: covertDateBeforeSubmit(values?.issuing_date),
        last_name: values?.last_name?.toUpperCase(),
        middle_name: !!values?.middle_name?.length ? values.middle_name?.toUpperCase() : undefined,
        nationality: values?.nationality ?? '',
        number: values?.number?.toUpperCase(),
        valid_until: covertDateBeforeSubmit(values?.date_of_expiry),
      };
      await updateUser(userId, { licence: payloadLicence })
        .then(() => {
          nextStep(1);
        })
        .catch((error) => {
          console.log(error);
          dispatch({ type: 'HIDE_LOADING' });
        });
    } catch (err) {
      console.log(err);
    }
  };

  const { t } = useTranslation();

  return (
    <FormWrapper>
      <div id="onfido-mount"></div>
      <Text color="darkPrimary" variant="title">
        {t('VERIFY_IDENTITY')}
      </Text>
      <Text variant="content" color="darkSecondary">
        {t('VERIFY_IDENTITY_LICENSE_SUBTITLE')}
      </Text>
      <Formik initialValues={{ ...licenceData, issuing_date: '' }} onSubmit={onSubmit} validationSchema={schema}>
        {(props) => (
          <StyledForm>
            <div>
              <FormMarginDense>
                <TextField
                  type="text"
                  name="first_name"
                  config={{
                    label: t('FIRST_NAME'),
                    toUpperCase: true,
                    InputProps: {
                      'data-testid': 'test_first_name',
                    },
                  }}
                />
              </FormMarginDense>

              <FormMarginDense>
                <TextField
                  type="text"
                  name="middle_name"
                  config={{
                    label: t('MIDDLE_NAME'),
                    toUpperCase: true,
                    InputProps: {
                      'data-testid': 'test_middle_name',
                    },
                  }}
                />
              </FormMarginDense>

              <FormMarginDense>
                <TextField
                  type="text"
                  name="last_name"
                  config={{
                    label: t('LAST_NAME'),
                    toUpperCase: true,
                    InputProps: {
                      'data-testid': 'test_last_name',
                    },
                  }}
                />
              </FormMarginDense>
              <FormMarginDense>
                <DatePickerField
                  name="birthdate"
                  config={{
                    placeholder: 'DD/MM/YYYY',
                    label: t('DATE_OF_BIRTH'),
                    inputProps: {
                      'data-testid': 'test_dob',
                    },
                  }}
                  formik={props}
                />
              </FormMarginDense>

              <FormMarginDense>
                <CountryPickerField
                  name="issuing_country"
                  config={{
                    fullWidth: true,
                    label: t('ISSUING_COUNTRY'),
                    inputProps: {
                      'data-testid': 'test_country',
                    },
                  }}
                  initialValues={props.initialValues}
                />
              </FormMarginDense>

              <FormMarginDense>
                <TextField
                  type="number"
                  name="number"
                  config={{
                    label: t('LICENCE_NUMBER'),
                    toUpperCase: true,
                    InputProps: {
                      'data-testid': 'test_number',
                    },
                  }}
                />
              </FormMarginDense>

              <ExpireLicenseWrapper>
                <ExpireDateWrapper left>
                  <DatePickerField
                    name="issuing_date"
                    config={{
                      placeholder: 'DD/MM/YYYY',
                      label: t('VALID_FROM'),
                      inputProps: {
                        'data-testid': 'test_valid_from',
                      },
                    }}
                    formik={props}
                  />
                </ExpireDateWrapper>

                <ExpireDateWrapper>
                  <DatePickerField
                    name="date_of_expiry"
                    config={{
                      placeholder: 'DD/MM/YYYY',
                      label: t('VALID_TO'),
                      inputProps: {
                        'data-testid': 'test_valid_to',
                      },
                    }}
                    formik={props}
                  />
                </ExpireDateWrapper>
              </ExpireLicenseWrapper>
            </div>
            <FormMarginDense>
              <ButtonWrapper disabled={!props.isValid} fullWidth variant="contained" color="white" type="submit">
                {t('CONTINUE')}
              </ButtonWrapper>
            </FormMarginDense>
          </StyledForm>
        )}
      </Formik>
    </FormWrapper>
  );
};

ConfirmLicencePage.getLayout = KYCLayout;
export default ConfirmLicencePage;
