import Cookies from 'js-cookie';

export const saveToken = (accessToken: string) => {
  localStorage.setItem('accessToken', accessToken);
};

export const getAccessToken = () => {
  return localStorage.getItem('accessToken') ?? Cookies.get('accessToken');
};

export const clearSavedToken = () => {
  localStorage.removeItem('accessToken');
};
