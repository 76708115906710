import axios from 'axios';

const httpClient = axios.create({
  baseURL: process.env.REACT_APP_API3_URL,
  timeout: 60 * 1000,
  headers: {
    accept: 'application/json'
  },
});

httpClient.interceptors.response.use(
  (response) => response.data,
  (error) => Promise.reject(error.response.code),
);

export default httpClient;
