import { getInternalToken } from '@/utils/internalAuth';
import axios from 'axios';

const internalHttpClient = axios.create({
  baseURL: process.env.REACT_APP_API3_URL,
  timeout: 60 * 1000,
  headers: { accept: 'application/json' },
});

internalHttpClient.interceptors.request.use(
  (req: any) => {
    const accessToken = 'Bearer ' + getInternalToken();
    req.headers.Authorization = accessToken;
    return req;
  },
  (error) => Promise.reject(error),
);

internalHttpClient.interceptors.response.use(
  (response) => response,
  (error) => {
    return Promise.reject(error.response.data.error.message);
  },
);
export default internalHttpClient;
