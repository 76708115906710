import { Button, Text } from '@/components/common';
import { NOT_ACTIVE, PENDING, REJECTED, VERIFIED } from '@/components/common/Sidebar/constant';
import { FormLabel, FormMarginDense, FormWrapper } from '@/components/form';
import KYCElement from '@/components/page/Identity/KycElement';
import { useAppDispatch, useAppSelector } from '@/redux/hooks';
import { get } from 'lodash';
import { useEffect, useLayoutEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { KYCLayout } from '../../container/KYCLayout';
import { useOnfido } from '../../hook/useOnfido';
import { useUser } from '../../hook/useUser';
import { ContentWrapper } from './styles';
import CheckboxTerm from '@/components/page/Register/CheckboxTerm';
import { Box } from '@mui/material';
import { getUserInfoWithTokenExternal, updateUser } from '@/api/user';

const VerifyIdentity: any = () => {
  const { t } = useTranslation();
  const [userInfo, setUserInfo] = useState<Record<string, any>>({});
  const requiredKYC = get(userInfo, 'insurance.requires');
  const issuingCountry = get(userInfo, 'licence.issuing_country');
  const kycConsent = get(userInfo, 'kyc_consent');
  const [checkedConsent, setCheckedConsent] = useState(false);
  const [, , , nextStep] = useOnfido(userInfo?.id);
  const [reloadUser] = useUser(userInfo?.id);
  const dispatch = useAppDispatch();
  const [isNotStartedKYC, setStartedKYC] = useState<boolean>(true);

  useLayoutEffect(() => {
    if (requiredKYC) {
      const isNStartedKYC =
        requiredKYC?.filter((item: string) => {
          return item !== 'User.passport' && item !== 'User.selfie-in-verification';
        }).length === 3;
      setStartedKYC(isNStartedKYC);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requiredKYC]);

  useEffect(() => {
    const getData = async () => {
      const data = await getUserInfoWithTokenExternal();
      setUserInfo(data);
      dispatch({ type: 'user/setUserInfo', payload: data });
      dispatch({ type: 'auth/signInSuccess', payload: data.id });

    };
    getData();
    dispatch({ type: 'HIDE_LOADING' });
    reloadUser && reloadUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const KycElementData = [
    {
      ico: 'licence',
      content: t('DRIVING_LICENCE'),
      status: !!requiredKYC?.includes('User.licence') ? NOT_ACTIVE : VERIFIED,
    },
    {
      ico: 'selfie',
      content: t('FACIAL_VERIFICATION'),
      status: !!requiredKYC?.includes('User.selfie-in-verification')
        ? PENDING
        : !!requiredKYC?.includes('User.selfie')
        ? NOT_ACTIVE
        : VERIFIED,
    },
    {
      ico: 'home',
      content: t('HOME_ADDRESS'),
      status: !!requiredKYC?.includes('User.address') ? NOT_ACTIVE : VERIFIED,
    },
    {
      ico: 'licence',
      content: !issuingCountry || issuingCountry === 'GBR' ? null : t('PASSPORT'),
      status: !!requiredKYC?.includes('User.passport') ? NOT_ACTIVE : VERIFIED,
    },
  ].filter((item) => item.content);

  const handleSubmit = async () => {
    dispatch({ type: 'SHOW_LOADING' });
    if (checkedConsent) {
      try {
        const user = await updateUser(userInfo.id, { kyc_consent: true });
        if (user) dispatch({ type: 'user/updateUserKYCConsent' });
      } catch (err) {}
    }
    await nextStep(0);
  };

  const verifiedStep = KycElementData?.filter((item) => item.status === VERIFIED);
  const stillRequiredStep = KycElementData?.filter((item) => item.status === NOT_ACTIVE || item.status === PENDING);
  const rejectedStep = KycElementData?.filter((item) => item.status === REJECTED);
  const showConsent = !kycConsent || requiredKYC?.includes('User.licence');
  console.log(userInfo);
  if (!userInfo) return null;
  return (
    <FormWrapper>
      <div id="onfido-mount"></div>
      <Text color="darkPrimary" variant="title">
        {t('VERIFY_IDENTITY')}
      </Text>
      <Text variant="content" color="darkSecondary">
        {t('VERIFY_IDENTITY_SUBTITLE')}
      </Text>
      <ContentWrapper>
        <div>
          {isNotStartedKYC ? (
            <>
              <FormMarginDense>
                <FormLabel text={t('REQUIRED')} />
              </FormMarginDense>
              <FormMarginDense value="8">
                {!!requiredKYC &&
                  KycElementData.map((item, index) => {
                    return <KYCElement key={index} isLast={index === KycElementData.length - 1} {...item} />;
                  })}
              </FormMarginDense>
            </>
          ) : (
            <>
              {verifiedStep && verifiedStep.length ? (
                <>
                  <FormMarginDense>
                    <FormLabel text={t('VERIFIED')} />
                  </FormMarginDense>
                  <FormMarginDense value="8">
                    {verifiedStep.map((item, index) => {
                      return <KYCElement key={index} isLast={index === verifiedStep.length - 1} {...item} />;
                    })}
                  </FormMarginDense>
                </>
              ) : null}
              {stillRequiredStep && stillRequiredStep.length ? (
                <>
                  <FormMarginDense>
                    <FormLabel text={t('STILL_REQUIRED')} />
                  </FormMarginDense>
                  <FormMarginDense value="8">
                    {stillRequiredStep.map((item, index) => {
                      return (
                        <KYCElement
                          key={index}
                          isLast={index === stillRequiredStep.length - 1}
                          {...item}
                          status={PENDING}
                        />
                      );
                    })}
                  </FormMarginDense>
                </>
              ) : null}
              {rejectedStep.length ? (
                <>
                  <FormMarginDense>
                    <FormLabel text={t('REJECTED')} />
                  </FormMarginDense>
                  <FormMarginDense value="8">
                    {rejectedStep.map((item, index) => {
                      return <KYCElement key={index} isLast={index === rejectedStep.length - 1} {...item} />;
                    })}
                  </FormMarginDense>
                </>
              ) : null}
            </>
          )}
        </div>
        {!!requiredKYC && (
          <Box sx={{ mt: { lg: 8 } }}>
            {showConsent ? (
              <Box sx={{ mb: { xxs: 3, lg: 4 } }}>
                <CheckboxTerm
                  onClick={() => {
                    setCheckedConsent((prev) => !prev);
                  }}
                  checked={checkedConsent}
                  text={
                    <Trans
                      i18nKey="ACCEPT_DATA_SHARING_CONSENT"
                      components={{
                        link1: (
                          // eslint-disable-next-line jsx-a11y/anchor-has-content
                          <a href={t('DATA_SHARING_LINK')} target="blank" />
                        ),
                      }}
                    />
                  }
                  testId="test_keep_updated"
                />
              </Box>
            ) : null}
            <Button
              fullWidth
              disabled={showConsent && !checkedConsent}
              variant="contained"
              color="white"
              type="submit"
              onClick={handleSubmit}
            >
              {isNotStartedKYC ? t('START_VERIFICATION') : t('CONTINUE_VERIFICATION')}
            </Button>
          </Box>
        )}
      </ContentWrapper>
    </FormWrapper>
  );
};

VerifyIdentity.getLayout = KYCLayout;
export default VerifyIdentity;
