import { LoadingPage } from '@/components/common';
import Sidebar from '@/components/common/Sidebar';
import { useAppSelector } from '@/redux/hooks';
import { getAccessToken } from '@/utils/auth';
import { UserType, VERIFY_PHONE_PATH } from '@/utils/constant';
import { endUrlForStep } from '@/utils/helper';
import { styled } from '@mui/material';
import { get } from 'lodash';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { LIST_VERIFICATION_ROUTE } from '../constant';
import { useSidebar } from '../hook/useSidebar';

export const Wrapper = styled('div')(({ theme }) => ({
  minWidth: '100vw',
  minHeight: '100vh',
  backgroundColor: '#000',
  display: 'flex',
  [theme.breakpoints.down('lg')]: {
    flexDirection: 'column',
  },
}));

export const SidebarContainer = styled('div')(({ theme }) => ({
  backgroundColor: '#090909',
  width: '100%',
  position: 'relative',
  [theme.breakpoints.up('lg')]: {
    width: '360px',
  },
  [theme.breakpoints.up('xl')]: {
    width: '384px',
  },
}));

export const Content = styled('div', { name: 'Content' })(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  flex: '1 0',
  padding: '30px 24px 24px',
  backgroundColor: '#000',
  color: '#999',
  flexDirection: 'column',
  [theme.breakpoints.up('sm')]: {
    padding: '40px 40px',
  },
  [theme.breakpoints.up('md')]: {
    padding: '48px 48px',
  },
  [theme.breakpoints.up('xl')]: {
    padding: '88px 96px 88px 192px',
  },
  [theme.breakpoints.up('xxl')]: {
    padding: '88px 178px 88px 206px',
  },
  [theme.breakpoints.up('xxxl')]: {
    padding: '88px 336px 88px 432px',
  },
}));

export const KYCLayout = () => {
  const userId = useAppSelector((state: any) => get(state, 'user.userInfo.id'));
  const isLoading = useAppSelector((state: any) => state.loading);
  const currentStep = useAppSelector((state: any) => state.sidebar.currentStep);
  const userType = useAppSelector((state: any) => state.user.userType);
  const navigate = useNavigate();
  const location = useLocation();
  const [reloadStep] = useSidebar(userId);
  const isAddPaymentMethodForCP = useAppSelector((state: any) => state.user.isAddPaymentMethod);

  // useEffect(() => {
  //   if (userType == null) {
  //     navigate('/404');
  //     return;
  //   }

  //   if ((!userId && location.pathname !== VERIFY_PHONE_PATH) || !getAccessToken()) {
  //     navigate('/register');
  //     return;
  //   }

  //   if (currentStep === 2 && LIST_VERIFICATION_ROUTE.includes(location.pathname)) {
  //     return;
  //   }
  //   if (userType === UserType.CONCIERGE_PORTAL && currentStep <= 2) {
  //     navigate(endUrlForStep(userType, 2, isAddPaymentMethodForCP));
  //   } else {
  //     navigate(endUrlForStep(userType, currentStep, isAddPaymentMethodForCP));
  //   }
  // }, [currentStep, location.pathname, navigate, userId, userType, isAddPaymentMethodForCP]);

  // useEffect(() => {
  //   if (getAccessToken() && reloadStep) {
  //     reloadStep();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [currentStep]);

  return (
    <Wrapper className="fs-unmask">
      <Helmet>
        <meta name="theme-color" content="#090909" />
        <style>{'body { background-color: #090909 !important; }'}</style>
      </Helmet>
      {/* <SidebarContainer>
        <Sidebar />
      </SidebarContainer> */}
      <Content>
        {isLoading ? <LoadingPage /> : null}
        <Outlet />
      </Content>
    </Wrapper>
  );
};
