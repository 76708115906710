import axios from 'axios';

const httpClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 60 * 1000,
  headers: {
    accept: 'application/json'
  },
});

httpClient.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error.response.data),
);

export default httpClient;
